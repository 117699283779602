<template>
    <div class="bg-white shadow-lg rounded-lg">
        <div class="pt-10 ml-5">
            <button
                v-permission="['create']"
                class="rounded-lg border-orange-500 border text-orange-500 px-3 py-2 hover:bg-orange-500 hover:text-white duration-500 tranistion"
                @click.prevent="openDialog">
                <i class="el-icon-plus text-lg"></i>新增
            </button>
        </div>
        <el-dialog
            :visible.sync="showDialog"
            :close-on-click-modal="true"
            :modal-append-to-body="true"
            :close-on-press-escape="true"
            @close="closeDialog">
            <label>步驟Key</label>
            <el-input v-model="stepKey"></el-input>
            <button v-permission="['create']" class="blue-btn" @click.prevent="addColumn">新增</button>
        </el-dialog>
        <el-form ref="form" :model="form" :rules="rules">
            <table class="myTable">
                <thead>
                    <tr>
                        <th>步驟</th>
                        <th>圖片位置</th>
                        <th>內容</th>
                        <th>詳細描述</th>
                    </tr>
                </thead>
                <tbody v-if="Object.keys(form).length > 0">
                    <tr v-for="(data, key) in form" :key="key">
                        <td class="w-[50px] text-center cursor-pointer" @click="dropColmun(key)">
                            {{ key }}
                            <i class="icofont-ui-delete text-orange-600 text-2xl"></i>
                        </td>
                        <td>
                            <el-form-item :prop="`${key}.imgPath`">
                                <el-input v-model="form[key].imgPath"></el-input>
                            </el-form-item>
                        </td>
                        <td>
                            <el-form-item v-for="(value, key2) in form[key]['content']" :key="key2" class="mb-0" :prop="`${key}.content.${key2}`">
                                <label class="mb-0 leading-none">
                                    {{ key2 }}
                                    <el-input v-model="form[key]['content'][key2]"></el-input>
                                </label>
                            </el-form-item>
                        </td>
                        <td>
                            <el-form-item
                                v-for="(value, key2) in form[key]['description']"
                                :key="key2"
                                class="mb-0"
                                :prop="`${key}.description.${key2}`">
                                <label class="mb-0 leading-none">
                                    {{ key2 }}
                                    <el-input v-model="form[key]['description'][key2]"></el-input>
                                </label>
                            </el-form-item>
                        </td>
                    </tr>
                </tbody>
            </table>
        </el-form>
        <div class="my-10 mr-5 text-right">
            <el-button v-permission="['update']" class="mb-10" type="primary" native-type="submit" @click.prevent="onSubmit('form')">
                更新
            </el-button>
        </div>
    </div>
</template>

<script>
import firebase from "../../plugins/firebase";
export default {
    data() {
        return {
            form: {},
            rules: {},
            datas: {},
            // 打開彈窗
            showDialog: false,
            stepKey: null,
        };
    },
    methods: {
        // 設定表單資料
        setDefault(datas) {
            Object.keys(datas).forEach((objKey) => {
                this.$set(this.rules, `${objKey}.imgPath`, [
                    {
                        required: true,
                        message: "圖片路徑為必填",
                        trigger: "blur",
                    },
                ]);
                this.$set(this.form, objKey, {
                    imgPath: datas[objKey].imgPath,
                    content: datas[objKey].content,
                    description: datas[objKey].description,
                });
                Object.keys(this.form[objKey]["content"]).forEach(() => {
                    this.$set(this.rules, `${objKey}.content.tw`, [
                        {
                            required: true,
                            message: "中文描述為必填",
                            trigger: "blur",
                        },
                    ]);
                    this.$set(this.form[objKey], "content", {
                        tw: this.form[objKey]["content"]["tw"],
                        en: this.form[objKey]["content"]["en"],
                    });
                });
                Object.keys(this.form[objKey]["description"]).forEach(() => {
                    this.$set(this.form[objKey], "description", {
                        tw: this.form[objKey]["description"]["tw"],
                        en: this.form[objKey]["description"]["en"],
                    });
                });
            });
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                this.sendData();
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        // 儲存與更新錯誤訊息
        async sendData() {
            try {
                // 新增與更新錯誤訊息
                await firebase
                    .database()
                    .ref("create_order_steps")
                    .set({ ...this.form });
                this.getList();
                this.$message({
                    type: "success",
                    message: "更新成功",
                });
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "更新失敗",
                });
            }
        },
        // 取得列表
        async getList() {
            try {
                const data = await firebase.database().ref("create_order_steps").get();
                // 判斷是否有訊息
                if (data.exists()) {
                    this.form = {};
                    this.setDefault(data.val());
                }
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得數據失敗",
                });
            }
        },
        // 打開彈窗
        openDialog() {
            this.showDialog = true;
        },
        // 新增欄位
        addColumn() {
            // 預設表單資料
            function setDefaultData() {
                this.imgPath = null;
                this.content = {
                    tw: "",
                    en: "",
                };
                this.description = {
                    tw: "",
                    en: "",
                };
            }
            const defaultData = new setDefaultData();
            //如果沒有輸入值時 不作動
            if (this.$isEmpty(this.stepKey)) {
                return;
            }
            this.$set(this.form, this.stepKey, defaultData);
            this.$set(this.rules, `${this.stepKey}.imgPath`, [
                {
                    required: true,
                    message: "圖片路徑為必填",
                    trigger: "blur",
                },
            ]);
            this.$set(this.rules, `${this.stepKey}.content.tw`, [
                {
                    required: true,
                    message: "中文描述為必填",
                    trigger: "blur",
                },
            ]);
            this.closeDialog();
        },
        /**
         * 刪除欄位
         * @param { type String(字串) } key 刪除key
         */
        async dropColmun(key) {
            try {
                await this.$confirm(`刪除此步驟${key}?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
                this.$delete(this.form, key);
                firebase
                    .database()
                    .ref("create_order_steps/" + key)
                    .remove();
                this.$message({
                    type: "success",
                    message: "刪除成功",
                });
            } catch (err) {
                console.log(err);
            }
        },
        // 關閉彈窗
        closeDialog() {
            this.stepKey = null;
            // 關閉彈窗
            this.showDialog = false;
        },
    },
    created() {
        this.getList();
    },
};
</script>
